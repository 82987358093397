<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Import Data</span>
        <v-spacer />
        <v-btn class="mt-3" color="primary" href="/empact-template.csv" target="_blank">
          <v-icon>mdi-download</v-icon>
          <span class="ml-2">Download Example CSV</span>
        </v-btn>
      </v-card-title>
      <div class="p-3 d-flex align-baseline">
        <v-file-input v-model="selectedFile" name="csvFile" accept=".csv" placeholder="Pick an CSV File" label="Pick an CSV File"></v-file-input>
        <v-btn :disabled="!selectedFile" class="ml-5" color="secondary" @click="analyseFile">Analyse</v-btn>
      </div>

      <div v-if="analysing">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>

      <div v-if="analysisSuccessful">
        <template v-if="existingEmails.length">
          <v-divider />
          <div class="py-5">
            <div class="pl-4 font-weight-bold">There are existing Individual(s) found!</div>
            <ul style="list-style: disc" class="ml-4">
              <li v-for="(item, index) in existingEmails" :key="index" class="body-2">{{ item }}</li>
            </ul>
          </div>
        </template>
        <template v-if="missingOutcomes.length">
          <v-divider />
          <div class="py-5">
            <div class="pl-4 font-weight-bold">There are mismatched Outcome(s) found!</div>
            <ul style="list-style: disc" class="ml-4">
              <li v-for="(item, index) in missingOutcomes" :key="index" class="body-2">{{ item }}</li>
            </ul>
          </div>
        </template>
        <template v-if="missingChallenges.length">
          <v-divider />
          <div class="py-5">
            <div class="pl-4 font-weight-bold">There are mismatched Challenge(s) found!</div>
            <ul style="list-style: disc" class="ml-4">
              <li v-for="(item, index) in missingChallenges" :key="index" class="body-2">{{ item }}</li>
            </ul>
          </div>
        </template>
        <template v-if="missingTechAreas.length">
          <v-divider />
          <div class="py-5">
            <div class="pl-4 font-weight-bold">There are mismatched TechArea(s) found!</div>
            <ul style="list-style: disc" class="ml-4">
              <li v-for="(item, index) in missingTechAreas" :key="index" class="body-2">{{ item }}</li>
            </ul>
          </div>
        </template>
      </div>
      <div v-else class="text-center mb-3">
        <div v-if="invalidDates">Some 'FundingLastActive' dates are invalid. Date format should be DD-MM-YYYY</div>
      </div>

      <div v-if="analysisSuccessful" class="text-center pb-4">
        <v-btn :disabled="!selectedFile" class="ml-5" color="primary" style="width: 200px" @click="uploadFile">Upload</v-btn>
      </div>

      <div v-if="dataProcessing" class="d-flex justify-center align-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>

      <div v-if="uploadSuccessful" class="text-center pb-4">
        <div class="font-weight-bold">Upload Successful</div>
        <div v-if="analyseResult">
          <div>{{ analyseResult.createdIndividualCount }} individual created</div>
          <div>{{ analyseResult.updatedIndividualCount }} individual updated</div>
          <div>{{ analyseResult.createdCompanyCount }} company created</div>
          <div>{{ analyseResult.updatedCompanyCount }} company updated</div>

          <v-data-table :items="analyseResult.processResults" :headers="processResultHeaders">
            <template v-slot:item.Success="{ item }">
              <v-icon v-if="item.Success" color="green">mdi-check-circle</v-icon>
              <v-icon v-else color="red">mdi-close-circle</v-icon>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import dayjs from 'dayjs';
import { importData } from '@/helpers/api';
import * as CSV from '@/helpers/csv';
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

export default {
  name: 'ImportData',
  data: () => ({
    // rules: [(value) => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!'],
    selectedFile: null,
    csvData: null,
    missingOutcomes: [],
    missingChallenges: [],
    missingTechAreas: [],
    existingEmails: [],
    analysisSuccessful: false,
    analysing: false,
    analyseResult: null,
    invalidDates: false,
    uploadSuccessful: false,
    dataProcessing: false,
    processResultHeaders: [
      { text: 'Success', value: 'Success' },
      { text: 'First Name', value: 'Firstname' },
      { text: 'Last Name', value: 'Lastname' },
      { text: 'Email', value: 'Email' },
    ],
  }),
  computed: {
    ...mapState(['outcomes', 'challenges', 'techAreas']),
  },
  methods: {
    ...mapActions(['checkEmailExistBulk']),
    async uploadFile() {
      this.uploadSuccessful = false;
      this.dataProcessing = true;
      const formData = new FormData();
      formData.append('csvFile', this.selectedFile);

      try {
        const response = await importData(formData);
        this.dataProcessing = false;
        console.log(response);

        if (response?.state) {
          console.log('success');
          this.$set(this, 'analyseResult', response.data);
          this.uploadSuccessful = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async analyseFile() {
      this.analysing = true;
      this.analysisSuccessful = false;
      this.uploadSuccessful = false;
      this.invalidDates = false;

      try {
        const result = await CSV.parse(this.selectedFile);
        console.log(result);

        this.csvData = result.data;

        if (this.csvData.length && this.csvData[this.csvData.length - 1]['Firstname'] === '\n') {
          this.csvData.pop();
        }

        await this.analyseData();
        this.checkOutcomes();
        this.checkChallenges();
        this.checkTechAreas();
        const funderFieldsInvalid = this.checkFunderFields();

        if (funderFieldsInvalid) {
          this.analysisSuccessful = false;
        } else {
          this.analysisSuccessful = true;
        }

        this.analysing = false;
      } catch (e) {
        this.$toast.error('Failed to parse CSV. Validate your CSV file.');
        this.analysing = false;
        this.analysisSuccessful = false;
      }
    },
    async analyseData() {
      const emails = [];
      this.existingEmails = [];

      this.missingOutcomes = [];
      this.missingChallenges = [];
      this.missingTechAreas = [];

      const csvOutcomes = [];
      const csvTechAreas = [];
      const csvChallenges = [];

      this.csvData.forEach((record) => {
        emails.push(record['Email']);

        // Outcomes
        const recordOutcomes = record['Outcomes'].trim().split('|');
        recordOutcomes
          .map((t) => t.trim())
          .filter((t) => t)
          .forEach((outcome) => {
            const index = csvOutcomes.indexOf(outcome);
            if (index === -1) {
              csvOutcomes.push(outcome);
            }
          });

        const recordTechAreas = record['TechAreas'].trim().split('|');
        recordTechAreas
          .map((t) => t.trim())
          .filter((t) => t)
          .forEach((techArea) => {
            const index = csvTechAreas.indexOf(techArea);
            if (index === -1) {
              csvTechAreas.push(techArea);
            }
          });

        const recordChallenges = record['Challenges'].trim().split('|');
        recordChallenges
          .map((t) => t.trim())
          .filter((t) => t)
          .forEach((challenge) => {
            const index = csvChallenges.indexOf(challenge);
            if (index === -1) {
              csvChallenges.push(challenge);
            }
          });
      });

      this.existingEmails = await this.checkEmailExistBulk(emails);

      // Find new outcomes
      const newOutcomes = [];
      csvOutcomes
        .map((t) => t.trim())
        .filter((t) => t)
        .forEach((outcome) => {
          if (!this.outcomes.some((o) => o.name === outcome)) {
            console.log(outcome);
            newOutcomes.push(outcome);
          }
        });
      this.missingOutcomes = newOutcomes;

      // Find new tech areas
      const newTechAreas = [];
      csvTechAreas
        .map((t) => t.trim())
        .filter((t) => t)
        .forEach((techArea) => {
          if (!this.techAreas.some((o) => o.name === techArea)) {
            newTechAreas.push(techArea);
          }
        });
      this.missingTechAreas = newTechAreas;

      // Find new challenges
      const newChallenges = [];
      csvChallenges
        .map((t) => t.trim())
        .filter((t) => t)
        .forEach((challenge) => {
          if (!this.challenges.some((o) => o.name === challenge)) {
            newChallenges.push(challenge);
          }
        });
      this.missingChallenges = newChallenges;
    },
    checkFunderFields() {
      let invalidDates = false;
      this.csvData.forEach((record) => {
        const organisationType = record['OrganisationType'].trim();

        if (organisationType === 'Funder') {
          // Check TypeOfFunding

          // Check FundingStageOfInterest

          // Check FundingTicketSize

          // Check FundingMinimumRevenue

          // Check FundingLastActive
          const fundingLastActive = record['FundingLastActive'];
          if (fundingLastActive.length === 10) {
            const isValidDate = dayjs(fundingLastActive, 'DD-MM-YYYY').isValid();
            if (!isValidDate) {
              invalidDates = true;
            }
          } else {
            invalidDates = true;
          }
        }
      });

      this.invalidDates = invalidDates;

      return this.invalidDates;
    },
    checkOutcomes() {},
    checkChallenges() {},
    checkTechAreas() {},
  },
};
</script>

import Papa from 'papaparse';

const detect = (input) => {
  const separators = [',', ';', '|', '\t'];
  const idx = separators.map((separator) => input.indexOf(separator)).reduce((prev, cur) => (prev === -1 || (cur !== -1 && cur < prev) ? cur : prev));
  return input[idx] || ',';
};

const parse = (file) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      transformHeader: (h) => h.trim(),
      error: (err) => {
        reject(err);
      },
      complete: (results) => {
        resolve({
          data: results.data,
          separator: results.meta.delimiter,
          headers: results.meta.fields,
        });
      },
    });
  });
};

export { detect, parse };
